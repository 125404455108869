import { Box, Button } from '@mui/material'
import React from 'react'

const LetterButton = (props) => {


  return (
    <div>
      <Button variant={props.element === 1 ? "contained" : 'outlined'} sx={{
        padding: 0, margin: 0, minWidth: 70, paddingY: 0
      }} onClick={props.handleClick}>
        <Box fontWeight={'fontWeightBold'} sx={{ fontSize: 25 }}>
          {props.children}
        </Box>
      </Button>
    </div>
  )
}

export default LetterButton