import { Box, Button } from '@mui/material'
import React from 'react'

const FinishButtons = (props) => {

  return (
    <div>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        width: "100%"
      }}>
        <Button variant='contained' onClick={props.reset} >
          RESET
        </Button>
        <Button variant='contained' disabled>
          SAVE
        </Button>
      </Box>
    </div >
  )
}

export default FinishButtons


