import { Box, Typography } from '@mui/material'
import React from 'react'

const ScoreBox = (props) => {
  const { snellenDenominatorForTestDistanceUsed, dictions } = props
  // console.log("props ScoreBox", dictions)

  let letterNumberPerLine = []
  let letterCorrectPerLine = []
  let testDistance = 20



  let correctedSnellenDenominator = snellenDenominatorForTestDistanceUsed.map((element) => {
    return (20 / testDistance) * element
  })
  let logMar = snellenDenominatorForTestDistanceUsed.map(
    (element) => {
      return Math.round(Math.log10(element / 20) * 100) / 100
    }
  )
  let linetolinChangeInLogMar = logMar.map((element, index) => {
    if (index === 0) {
      return 0
    }
    else {
      return Math.round((element - logMar[index - 1]) * 100) / 100
    }
  })

  console.log("LogMar", logMar)
  console.log("correctedSnellenDenominator", correctedSnellenDenominator)
  console.log("linetolinChangeInLogMar", linetolinChangeInLogMar)

  if (dictions !== undefined) {
    for (let i = 0; i < 10; i++) {
      if (dictions[i] !== undefined) {
        let summation = 0

        letterNumberPerLine.push(dictions[i].length)
        for (let j = 0; j < dictions[i].length; j++) {
          if (dictions[i][j] === 1) {
            summation++
          }
        }
        letterCorrectPerLine.push(summation)
      }
    }
  }
  // }
  console.log("letterNumberPerLine", letterNumberPerLine)
  console.log("letterCorrectPerLine", letterCorrectPerLine)

  let CalculatedLogMar = linetolinChangeInLogMar.map((element, index) => {
    let calculation = (letterCorrectPerLine[index] / letterNumberPerLine[index]) * element
    return (Math.round(calculation * 100)) / 100
  })

  console.log("CalculatedLogMar", CalculatedLogMar)

  let accum = 0

  let accumLogMar = CalculatedLogMar.map((element, index) => {
    if (index === 0) {
      accum = (logMar[index]) * (2 - (letterCorrectPerLine[index] / letterNumberPerLine[index]))
      return accum
    } else {
      accum = accum + element
      return Math.round(accum * 100) / 100
    }
  })

  console.log("accumLogMar", accumLogMar)

  const EffectiveLogMar = (20 * 10 ** (accumLogMar[9]))
  const FinalSnellen = Math.round((EffectiveLogMar) * 100) / 100
  const FinalLogMar = accumLogMar[9]
  console.log("EffectiveLogMar", EffectiveLogMar)
  console.log("FinalSnellen", FinalSnellen)
  console.log("FinalLogMar", FinalLogMar)
  return (
    <div>
      <Box p={3} sx={{ display: 'flex', justifyContent: "space-evenly" }}>

        <Box align="center">
          <Typography>SNELLEN</Typography>
          <Typography variant='h4'>20/{FinalSnellen}</Typography>
        </Box>
        <Box align="center">
          <Typography>LOGMAR</Typography>
          <Typography variant='h4'>{FinalLogMar}</Typography>

        </Box>
      </Box>
    </div>
  )
}

export default ScoreBox