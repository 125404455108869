import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import './App.css';
import Edit from './comp/Edit';
import FinishButtons from './comp/FinishButtons';
import ScoreBox from './comp/ScoreBox';
import TableSelect from './comp/TableSelect';
import Title from './comp/Title';

function App() {

  const defaultStrStore = {
    0: ["#"],
    1: ["#", "#"],
    2: ["#", "#", "#"],
    3: ["#", "#", "#", "#"],
    4: ["#", "#", "#", "#", "#"],
    5: ["#", "#", "#", "#", "#", "#"],
    6: ["#", "#", "#", "#", "#", "#", "#"],
    7: ["#", "#", "#", "#", "#", "#", "#", "#"],
    8: ["#", "#", "#", "#", "#", "#", "#", "#"],
    9: ["#", "#", "#", "#", "#", "#", "#", "#", "#", "#"]
  }


  let snellenDenominatorForTestDistanceUsed = [200, 100, 70, 50, 40, 30, 25, 20, 15, 10]

  const [strStoreState, setStrStoreState] = useState(false)

  const [dictions, setDictions] = useState({})

  const [strStore, setstrStore] = useState(defaultStrStore)




  // fx to create a copy of strStore with 0 values for diction
  const dictionCopy = (storeCopy) => {
    let dictionCopy = {}
    for (let i = 0; i < 10; i++) {
      dictionCopy[i] = Array(storeCopy[i].length).fill(0)
    }
    // save dictionCopy to dictions
    setDictions(dictionCopy)
  }

  useEffect(() => {
    setstrStore(defaultStrStore);
  }, []);

  useEffect(() => {
    if (strStore && strStore[0].length > 0) {
      dictionCopy(strStore);
    }
  }, [strStore]);

  const [tableSelectVis, setTableSelectVis] = useState(true)
  // console.log("strStore", strStore)


  const reset = () => {
    const newDictions = { ...dictions };
    for (let i = 0; i < 10; i++) {
      newDictions[i] = newDictions[i].map(() => 0);
    }
    setDictions(newDictions);
  };


  return (
    <div>


      <Container maxWidth="sm" >
        <Box sx={{ my: 2 }}>
          <Title />

          <Edit
            setTableSelectVis={setTableSelectVis}
            tableSelectVis={tableSelectVis}
            setStrStoreState={setStrStoreState}
            strStore={strStore}
            setStrStore={setstrStore}
          />

          <Box sx={{ display: tableSelectVis ? "block" : "none" }}>
            <TableSelect dictions={dictions}
              setDictions={setDictions}
              strStore={strStore}
              strStoreState={strStoreState}
              setStrStoreState={setStrStoreState}
              snellenDenominatorForTestDistanceUsed={snellenDenominatorForTestDistanceUsed} />
            <ScoreBox
              dictions={dictions}
              snellenDenominatorForTestDistanceUsed={snellenDenominatorForTestDistanceUsed} />
          </Box>
          <Box pb={7} sx={{ display: tableSelectVis ? "block" : "none" }}>
            <FinishButtons reset={reset} />

          </Box>
          <Box align="right">
            <Typography variant='body2' color="lightgray">
              v.1.0.2
            </Typography>

          </Box>
        </Box>
      </Container>

    </div>
  );
}

export default App;
