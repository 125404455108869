import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import ColorAlerts from './ColorAlerts';
import EditInput from './EditInput';

const Edit = (props) => {

  const { setTableSelectVis, setStrStoreState, tableSelectVis, strStore, setStrStore } = props;

  const [rows, setRows] = useState({});

  const [alertVis, setAlertVis] = useState(false)

  console.log("editstrStore", strStore)
  console.log("rows", rows)


  const handleEditClick = () => {
    console.log("Edit Clicked")
    setTableSelectVis(false)

    setRows(Object.values(strStore).map((row) => row.join("")));

    console.log("rows", rows)
  }

  const handleSaveClick = () => {
    console.log("Save Clicked")

    // prevent empty rows
    for (let element of rows) {
      if (element.length === 0) {
        return setAlertVis(true)
      }
    };
    setAlertVis(false)
    setTableSelectVis(true)
    setStrStoreState(true)

    setStrStore(Object.values(rows).map((row) => row.split("")));
    console.log("strStore", strStore)
  }

  const handleCloseClick = () => {
    console.log("Close Clicked")

    setTableSelectVis(true)
  }

  const handleResetClick = () => {
    console.log("Reset Clicked")
    setStrStoreState(false)
    setStrStore([...Array(10)].map((_, i) => Array(i + 1).fill("#")))
    setRows([...Array(10)].map((_, i) => Array(i + 1).fill("#").join("")))
    setTableSelectVis(true)
  };

  const handleRowChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index] = value;
    setRows(updatedRows);
  };

  return (
    <div>
      <Box align="center">

        <Button variant='outlined' size="small" disabled={!tableSelectVis} onClick={handleEditClick}>Edit</Button>
      </Box>
      <Box sx={{ display: tableSelectVis ? "none" : "block" }}>


        {
          rows.length > 0 && rows.map((row, index) => {

            return (<Box display="flex" >
              <Box alignContent="center">
                <Typography sx={{ width: 40, textAlign: "right" }} variant='h5' >{[index + 1]}</Typography>
              </Box>
              <EditInput
                storeRowValue={row}
                setRowValue={(value) => handleRowChange(index, value)} index={index} />
            </Box>
            )
          })
        }


        <Box sx={{ display: alertVis ? "block" : "none" }} my={2}>
          <ColorAlerts>
            Please fill all the rows
          </ColorAlerts>
        </Box>
        <Box align="center" width="70%" marginX="auto"
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          <Button variant="outlined" size='large' onClick={handleSaveClick}>Save</Button>
          <Button variant="outlined" size="large" onClick={handleCloseClick}>Close </Button>
        </Box>
        <Box mt={5} align="center" width="70%" marginX="auto"
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          <Button variant="outlined" onClick={handleResetClick}>Reset </Button>
        </Box>
      </Box>
    </div >
  )
}

export default Edit