import { Box } from '@mui/material'
import React from 'react'

function Title() {
  return (

    <div>
      {/* material ui center */}
      <Box sx={{ textAlign: 'center', }}>
        {/* // set box to h1 */}
        <Box component='h1'>logMAR Chart</Box>
      </Box>
    </div>

  )
}

export default Title