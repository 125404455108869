import Alert from '@mui/material/Alert';
import * as React from 'react';

export default function ColorAlerts(props) {
  return (
    <Alert severity="error">
      {props.children}
    </Alert>
  );
}
