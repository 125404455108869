import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import * as React from 'react';

const EditInput = (props) => {
  const { storeRowValue, setRowValue, index } = props
  // console.log("index", index)
  const handleChange = (event) => {
    // Update the corresponding row value
    const uppercasedValue = event.target.value.toUpperCase();

    setRowValue(uppercasedValue);
  };
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          variant="outlined"
          value={storeRowValue}
          onChange={handleChange}
          inputProps={{ maxLength: 10 }}
          helperText={`${storeRowValue.length}/${10} characters`}
        />
      </Box>

    </div>
  )
}

export default EditInput